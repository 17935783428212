// Here you can add other styles
.list-acionamentos {
  height: 27vh;
  overflow: auto;
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.btn-pagination {
  margin: 0 2px;
}

.dash-filter {
  border-bottom: solid;
  padding-bottom: 5px;
}

.chart-wrapper {
  padding-bottom: 8px;
  border-bottom: 1px solid gray;
}

#btnSubmmit {
  margin-top: 12px;
}